import find from "lodash/find";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { useScrollBlock } from "../../../hooks/useScrollBlock";
import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";
import summaryActions from "../../../state/summary/actions";

import { configurationHelper } from "../../../utils/configurationHelper";
import { removeDecimalIfWhole } from "../../../utils/removeDecimalIfWhole";

import { useProductDetails } from "../../../hooks/useProductDetails";

import InfoIcon from "jsx:../../../images/icon-info.inline.svg";
import { CardButtonBase } from "../common/card/CardButtonBase";
import { Chip } from "../common/chip";
import { Info } from "../common/info";
import { Select } from "../common/select";
import { SkeletonCard } from "../common/skeleton-card";
import { ComparisonTable } from "../comparison-table";
import { Modal } from "../modal/Modal";

import { ERROR_MANAGEMENT_LEVEL } from "../constants";

export function SectionManagement() {
  const modalOpenRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [{ configuration: state }, dispatch] = useAppState();
  const productData = useProductDetails();
  const { data } = productData[state.productType] || {};
  const {
	  getManagementOptionsByRegion,
	  getDataByRegion,
	  getRegions,
	} = configurationHelper(data);
  const showSkeleton =
    state.isLoading || ["api-fetch", "management"].includes(state.isError);

  const managementOptions = useMemo(() => {
    if (!data) {
      return {};
    }
    
    const options = getManagementOptionsByRegion(state.serverLocation);

    return options;
  }, [data, state.serverLocation, state.productCode, state.resetToggle]);

  const managementPriceMap = useMemo(() => {
    if (Object.keys(managementOptions).length === 0) {
      return {};
    }

    const managementLevelMap = {
      "Self-Managed": "",
      "Core-Managed": "",
      "Fully-Managed": "",
    };

    if (managementOptions[state.managementLevel]) {
      Object.keys(managementOptions).forEach((levelKey) => {
        const firstDistroKey = Object.keys(managementOptions[levelKey])[0];
        const firstDistroObject = managementOptions[levelKey]?.[firstDistroKey];
        const priceArray = firstDistroObject?.[0]?.control_panels?.[0]?.price;
        const cost = removeDecimalIfWhole(getMonthlyCost(priceArray));

        managementLevelMap[levelKey] = cost;
      });
    }

    return managementLevelMap;
  }, [managementOptions, state.managementLevel]);

  useEffect(() => {
    const hasManagementOptions = Object.keys(managementOptions).length;
    const regions = getRegions();
    const currentRegion = find(regions, {name: state.serverLocation});

    if (regions.length && !currentRegion) {
      dispatch(configActions.setServerLocation(regions[0].name));
    } else if (
      hasManagementOptions === 0 &&
      regions.length === 1 &&
      regions[0].name !== state.serverLocation
    ) {
      dispatch(configActions.setServerZoneId(regions[0].zone_id));
      dispatch(configActions.setServerRegionId(regions[0].region_id));
      dispatch(configActions.setServerLocation(regions[0].name));
    } else {
      dispatch(
        configActions.setIsError(
          !state.isLoading && hasManagementOptions === 0 ? "management" : "",
        ),
      );
      dispatch(
        configActions.setErrorMessage(
          !state.isLoading && hasManagementOptions === 0
            ? ERROR_MANAGEMENT_LEVEL
            : "",
        ),
      );
    }

    dispatch(
      configActions.setAvailableDistros(
        hasManagementOptions ? managementOptions[state.managementLevel] : {},
      ),
    );
    dispatch(
      summaryActions.setManagement({
        value: hasManagementOptions ? state.managementLevel : "Self-Managed",
        cost: hasManagementOptions
          ? getManagementCostByLevel(state.managementLevel)
          : 0,
      }),
    );
  }, [
    managementOptions,
    state.managementLevel,
    state.setOperatingSystemId,
    state.productCode,
  ]);

  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === "Escape") {
        handleModalOpen(false);
        allowScroll();
      }
    };

    addEventListener("keydown", handleKeydown);

    return () => {
      removeEventListener("keydown", handleKeydown);
      allowScroll();
    };
  }, []);

  function getMonthlyCost(priceArray = []) {
    const priceObject = find(priceArray, { unit: "month" });

    return priceObject?.amount ? Number(priceObject.amount) : "";
  }

  function getManagementCostByLevel(level) {
    const regionData = getDataByRegion(state.serverLocation);
    const managementLevelObject = find(regionData?.management, { name: level });

    return getMonthlyCost(managementLevelObject?.price);
  }

  function getChipCostByManagementLevel(level) {
    const cost = getManagementCostByLevel(level);

    return Number(cost) === 0 ? "No Cost" : `+$${cost} Monthly`;
  }

  function handleModalOpen(isOpen) {
    setIsModalOpen(isOpen);

    if (!isOpen) {
      modalOpenRef.current.focus();
      allowScroll();
    } else {
      blockScroll();
    }
  }

  if (state.isBareMetal) {
    return null;
  }

  return (
    <div className="mb-16 3xl:mb-28">
      <h3 className="text-xl font-normal mt-0 mb-2">Management</h3>
      <div className="mb-6">
        <p>
          {`Not quite ready to self-manage your server? We offer additional management levels that connect you with our expert support team. `}
          <button
            className="text-lw-primary-alt hover:text-lw-primary-alt-hover"
            ref={modalOpenRef}
            onClick={() => handleModalOpen(true)}
          >
            Learn more.
          </button>
        </p>
      </div>

      <Modal
        title="Management Comparison"
        isOpen={isModalOpen}
        setIsOpen={(isOpen) => handleModalOpen(isOpen)}
      >
        <ComparisonTable />
      </Modal>

      <div className="mb-20 lg:mb-16 3xl:mb-28">
        <div className="hidden gap-4 sm:grid sm:grid-cols-3">
          {showSkeleton
            ? Array(3)
                .fill()
                .map((_, index) => (
                  <SkeletonCard key={index} className="h-[95px]" />
                ))
            : null}
          {!state.isLoading &&
          (state.isError === "" || state.isError === "api-post") &&
          managementOptions
            ? Object.keys(managementOptions).map((level, index) => (
                <CardButtonBase
                  className="flex-col justify-between !items-start gap-2"
                  key={`pill-${index}`}
                  isSelected={state.managementLevel === level}
                  onClick={() =>
                    dispatch(configActions.setManagementLevel(level))
                  }
                >
                  <span className="text-xl md:text-base xl:text-xl">
                    {level}
                  </span>
                  {managementPriceMap[level] ? (
                    <Chip>{getChipCostByManagementLevel(level)}</Chip>
                  ) : null}
                </CardButtonBase>
              ))
            : null}
        </div>

        {!state.isLoading &&
        (state.isError === "" || state.isError === "api-post") &&
        Object.keys(managementOptions).length ? (
          <Select
            controlElemClass="sm:hidden"
            onChange={(event) =>
              dispatch(configActions.setManagementLevel(event.target.value))
            }
            value={state.managementLevel}
          >
            {Object.keys(managementOptions).map((level, index) => (
              <option key={`select-${index}`} value={level}>
                {level}
                {managementPriceMap[level]
                  ? ` - ${getChipCostByManagementLevel(level)}`
                  : null}
              </option>
            ))}
          </Select>
        ) : null}
        {state.managementLevel === "Fully-Managed" ? (
          <Info className="mt-6"  icon={<InfoIcon width="16" height="16" />}>
            <span>
              Fully-Managed support requires the use of one of our supported
              control panels.
            </span>
          </Info>
        ) : null}
      </div>
    </div>
  );
}
